import React from "react";
import ReactMarkdown from "react-markdown";
import { graphql, Link } from "gatsby";
import Helmet from "react-helmet";
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import { introImg } from "../../static/introImg";
import { iconInHome } from "../data/icon";

const isBrowser = typeof window !== "undefined";

const Home = (props) => {
  const about = props.data.site.siteMetadata.about.default;
  const menu = {
    main: props.data.allMainMenuJson.edges,
    service: props.data.allServiceMenuJson.edges,
    footer: props.data.allFooterMenuJson.edges,
    subFooter: props.data.allSubFooterMenuJson.edges,
  };
  const productsHome = props.data.products_in_home.edges;
  const { slug } = props.pageContext;

  return (
    <Layout bodyClass="page-home" lang="default" menu={menu} slug={slug}>
      <SEO title="Internet to support your business" />
      <Helmet>
        <meta name="description" content="Internet to support your business" />
        <meta name="color-scheme" content="light only" />
      </Helmet>
      <div className="intro-block container py-6">
        <div className="row flex-column-reverse flex-md-row">
          <div className="col-12 col-md-6 col-lg-6 align-self-center">
            <div className="payoff sub-tagline mt-3">{about.replace(/  +/g, "  ")}</div>
          </div>

          <div
            className={
              isBrowser && window.innerWidth > 960
                ? "col-8 col-md-6 col-lg-5 ml-auto"
                : "col-8 col-md-6 col-lg-5"
            }
          >
            <div
              style={isBrowser && window.innerWidth > 960 ? { marginLeft: "-80px" } : {}}
            >
              {introImg}
            </div>
          </div>
        </div>
      </div>

      {productsHome.map(({ node }, id) =>
        id % 2 == 0 ? (
          <section key={id} className="middle-block">
            <div className="container pt-3 pb-4 pt-md-6 pb-md-7">
              <div className="row flex-column-reverse flex-md-row">
                <div className="col-md-8 col-12 mt-2 home-box">
                  <Link to={node.frontmatter.link_to} title="Leggi tutto">
                    <div className="sub-tagline">{node.frontmatter.title}</div>
                  </Link>
                  <div className="sub-tagline-2 mt-1">
                    {node.frontmatter.subtitle && node.frontmatter.subtitle}
                  </div>
                  <div className="incipit">
                    <div className="mt-2">
                      <ReactMarkdown>{node.rawMarkdownBody}</ReactMarkdown>
                      <div className="mt-3">
                        <Link to={node.frontmatter.link_to}>Leggi tutto</Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 d-md-flex align-items-center justify-content-center">
                  <div className="d-flex">
                    <div className="align-items-center justify-content-center ">
                      {iconInHome[node.frontmatter.icon]}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section key={id}>
            <div className="container pt-3 pb-4 pt-md-6 pb-md-7">
              <div className="row">
                <div className="col-md-4 col-12 d-md-flex align-items-center justify-content-center">
                  {iconInHome[node.frontmatter.icon]}
                </div>
                <div className="col-md-8 col-12 mt-2 home-box">
                  <Link to={node.frontmatter.link_to} title="Leggi tutto">
                    <div className="sub-tagline">{node.frontmatter.title}</div>
                  </Link>
                  <div className="sub-tagline-2 mt-1">
                    {node.frontmatter.subtitle && node.frontmatter.subtitle}
                  </div>
                  <div className="incipit">
                    <div className="mt-2">
                      <ReactMarkdown>{node.rawMarkdownBody}</ReactMarkdown>
                      <div className="mt-3">
                        <Link to={node.frontmatter.link_to}>Leggi tutto</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )
      )}
    </Layout>
  );
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        about {
          default
        }
      }
    }

    products_in_home: allMarkdownRemark(
      filter: { frontmatter: { lang: { eq: "default" }, incipit: { eq: "yes" } } }
      sort: { fields: frontmatter___sort, order: ASC }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            subtitle
            lang
            incipit
            icon
            sort
            link_to
            one_page_scroll
          }
          fields {
            slug
          }
          rawMarkdownBody
        }
      }
    }

    allMainMenuJson(filter: { lang: { eq: "default" } }) {
      edges {
        node {
          name
          url
          lang
          submenu {
            lang
            name
            anchors {
              lang
              name
              url
            }
            url
          }
        }
      }
    }
    allFooterMenuJson(filter: { lang: { eq: "default" } }) {
      edges {
        node {
          name
          url
          lang
          submenu {
            lang
            name
            anchors {
              lang
              name
              url
            }
            url
          }
        }
      }
    }
    allServiceMenuJson(filter: { lang: { eq: "default" } }) {
      edges {
        node {
          name
          url
          lang
          submenu {
            lang
            name
            png_icon
            url
          }
        }
      }
    }
    allSubFooterMenuJson(filter: { lang: { eq: "default" } }) {
      edges {
        node {
          name
          url
          lang
        }
      }
    }
  }
`;

export default Home;
